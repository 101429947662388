


















































import { Component, Vue, Prop } from "vue-property-decorator";
import MiaoshuFenzuLianxu from "./reports/miaoshu-fenzu-lianxu.vue";
import MiaoshuFenzuFenlei from "./reports/miaoshu-fenzu-fenlei.vue";
import MiaoshuWufenzuLianxu from "./reports/miaoshu-wufenzu-lianxu.vue";
import MiaoshuWufenzuFenlei from "./reports/miaoshu-wufenzu-fenleii.vue";
import Xiangguanxing from "./reports/xiangguanxing.vue";
import Guanlianguize from "./reports/guanlianguize.vue";
import ChayiDuliFenlei from "./reports/chayi-duli-fenlei.vue";
import ChayiDuliLianxu from "./reports/chayi-duli-lianxu.vue";
import ChayiPeiduiFenlei from "./reports/chayi-peidui-fenlei.vue";
import ChayiPeiduiLianxu from "./reports/chayi-peidui-lianxu.vue";

@Component({
  components: {
    MiaoshuFenzuLianxu,
    MiaoshuFenzuFenlei,
    MiaoshuWufenzuLianxu,
    MiaoshuWufenzuFenlei,
    Xiangguanxing,
    Guanlianguize,
    ChayiDuliFenlei,
    ChayiDuliLianxu,
    ChayiPeiduiFenlei,
    ChayiPeiduiLianxu,
  },
})
export default class ReportTransfer extends Vue {
  @Prop()
  private result: any;
  private resultChange() {
    this.$emit("update:result", this.result);
  }
  /**
   * @description 关联规则导出数据
   */
  private guanlianguizeExport(name: any) {
    this.$emit("download", "关联规则", name);
  }
  private mounted() {
    // console.log(this.result);
  }
}
